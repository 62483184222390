import React, { useContext } from 'react'
import { Card } from '../components/Card'
import { DocumentFlowContext } from '../contexts/DocumentFlowContext'
import { FormConsent } from '../components/FormConsent'
import { FormContent } from '../components/FormContent'
import { useIntl } from 'react-intl'

export const DocumentTemplate = () => {
  const { locale } = useIntl()
  const { templates, stepIndex } = useContext(DocumentFlowContext)
  const template = templates[stepIndex - 1]
  const templateId = (stepIndex - 1).toString()

  return (
    <>
      <Card>
        <FormContent
          atn={template.atn}
          templateId={templateId}
          activeLang={locale}
        />
      </Card>
      <Card>
        <FormConsent templateId={templateId} activeLang={locale} />
      </Card>
    </>
  )
}
